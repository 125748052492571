import ApiService from '@/common/api/api.service';

let apiBasePath = 'PeriodoContabil';
const LancamentoPeriodoService = {
  // FUNÇÕES DE BUSCA:
  async obterLancamentoPeriodoPaginado(filtro, paginacao) {
    let { data } = await ApiService.get(
      `${apiBasePath}/listar-lancamentos-por-filtro-paginado`,
      {
        params: {
          ...filtro,
          ...paginacao,
        },
      }
    );
    return data;
  },

  // Relatorios
  async gerarRelatorioLancamentoPorPeriodo(filtro) {
    let { data } = await ApiService.get(
        `${apiBasePath}/relatorio-contabil/lancamento-periodo`,
      {
        params: {
          ...filtro,
        },
      }
    );
    return data;
  },
};

export default LancamentoPeriodoService;