<template>
  <div>
    <topbar
      :titulo="$t('CONTABIL.RELATORIO_LANCAMENTOS_POR_PERIODO')"
      :subtitulo="$t('CONTABIL.RELATORIO_LANCAMENTOS_POR_PERIODO_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    />
    <filtro-lancamento-por-periodo
      @atualizar-filtro="atualizarFiltro"
      class="mt-3"
      @limpar="limpar"
      @filtrar="filtrar"
      @gerar-relatorio="gerarRelatorio"
      :btn="btn"
      @refs="capturarRefs"
    />
    <tabela-lancamentos-por-periodo :itens="lancamentosPorPeriodo" />
    <paginacao v-model="paginacao" :total="paginacao.total" @input="filtrar" />
  </div>
</template>

<script>
//AUX & UTILS
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';

//SERVICES
import LancamentoPeriodoService from '@/common/services/contabil/lancamentoPeriodo.service';

//COMPONENTS
import Paginacao from '@/components/paginacao/Paginacao';
import FiltroLancamentoPorPeriodo from '@/views/contabil/relatorios/relatorio-lancamentos-por-periodo/components/FiltroLancamentoPorPeriodo';
import TabelaLancamentosPorPeriodo from '@/views/contabil/relatorios/relatorio-lancamentos-por-periodo/components/TabelaLancamentosPorPeriodo';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  name: 'ContabilRelatorioLancamentoPorPeriodo',
  components: {
    Paginacao,
    FiltroLancamentoPorPeriodo,
    TabelaLancamentosPorPeriodo,
    Topbar,
  },
  data() {
    return {
      btn: true,
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      filtro: {},
      lancamentosPorPeriodo: [],
      breadcrumbs: [
        { titulo: this.$t('CONTABIL.CONTABIL') },
        { titulo: this.$t('CONTABIL.RELATORIOS') },
        { titulo: this.$t('CONTABIL.RELATORIO_LANCAMENTOS_POR_PERIODO') },
      ],
    };
  },
  methods: {
    capturarRefs(refs) {
      this.$refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    filtrar() {
      if (!this.validarFormulario()) return;

      this.$store.dispatch(START_LOADING);
      LancamentoPeriodoService.obterLancamentoPeriodoPaginado(
        this.filtro,
        this.paginacao
      )
        .then(({ data }) => {
          this.lancamentosPorPeriodo = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
          if (this.lancamentosPorPeriodo.length > 0) {
            this.btn = false;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    gerarRelatorio() {
      this.$store.dispatch(START_LOADING);
      LancamentoPeriodoService.gerarRelatorioLancamentoPorPeriodo(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], {
              type: 'application/pdf',
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    limpar() {
      this.lancamentosPorPeriodo = [];
      this.btn = true;
    },

    atualizarFiltro(filtro) {
      this.filtro = filtro;
    },
  },
};
</script>
