<template>
  <div>
    <h4>
      {{ $t('ACADEMICO.FILTROS') }}
    </h4>
    <b-row>
      <b-col md="2">
        <input-date-only-btn
          ref="dataInicio"
          v-model="filtro.dataInicio"
          :label="$t('FINANCEIRO.PERIODO_INICIAL')"
          required
        />
      </b-col>

      <b-col md="2">
        <input-date-only-btn
          ref="dataFim"
          v-model="filtro.dataFim"
          :label="$t('FINANCEIRO.PERIODO_FINAL')"
          required
        />
      </b-col>

      <b-col md="2">
        <input-text
          ref="codigoDocumento"
          v-model="filtro.codigoDocumento"
          :label="$t('CONTABIL.NUMERO_DOCUMENTO')"
          @enter="filtrar"
        />
      </b-col>

      <b-col md="2">
        <input-text
          ref="objetoReferencia"
          v-model="filtro.objetoReferencia"
          :label="$t('CONTABIL.OBJETO_REFERENCIA')"
          :options="opcoes.tiposObjetoReferenciaEnum"
          @enter="filtrar"
        />
      </b-col>

      <b-col md="2">
        <input-text
          ref="valor"
          v-model="filtro.valor"
          :label="$t('CONTABIL.VALOR')"
          :options="opcoes.valor"
          type="number"
          @enter="filtrar"
        />
      </b-col>

      <b-col md="2">
        <input-select-search
          ref="tipoMovimento"
          v-model="filtro.tipoMovimento"
          :label="$t('FINANCEIRO_LANCAMENTOS.TIPO_LANCAMENTO')"
          :options="opcoes.tiposLancamentoEnum"
        />
      </b-col>

      <b-col md="8">
        <input-select-search
          ref="planoContaId"
          v-model="filtro.planoContaId"
          :label="$t('CONTABIL.CONTA_CONTABIL')"
          :options="opcoes.planosConta"
        />
      </b-col>
      <b-col cols="12" md="auto">
        <b-btn
          class="botao-acao-filtro mb-3 w-100"
          variant="primary"
          @click="filtrar"
          >{{ $t('GERAL.LISTAR') }}</b-btn
        >
      </b-col>
      <b-col cols="12" md="auto">
        <b-btn
          class="botao-acao-filtro mb-3 w-100"
          variant="secondary"
          @click="limpar"
          >{{ $t('GERAL.LIMPAR') }}</b-btn
        >
      </b-col>
      <b-col md="auto">
        <b-btn
          :disabled="btn"
          variant="info"
          class="botao-acao-filtro mb-3 w-100"
          @click="gerarRelatorio"
          >{{ $t('GERAL.GERAR_RELATORIO') }}</b-btn
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Aux & helpes
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

//servives
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import PlanoContaService from '@/common/services/plano-conta/plano-conta.service.js';

// Components:
import {
  InputText,
  InputSelectSearch,
  InputDateOnlyBtn,
} from '@/components/inputs';

export default {
  name: 'FiltroContratosPorTurma',

  props: {
    form: { type: Object, default: Object },
    btn: { type: Boolean, default: Boolean },
  },

  components: {
    InputText,
    InputSelectSearch,
    InputDateOnlyBtn,
  },
  data() {
    return {
      filtro: {
        dataInicio: '',
        dataFim: '',
        codigoDocumentoodigoDocumento: '',
        planoContaId: '',
        objetoReferencia: '',
        tipoMovimento: '',
        valor: '',
      },
      opcoes: {
        tiposLancamentoEnum: [],
        planosConta: [],
      },
    };
  },
  mounted() {
    this.getTipoLancamentoEnum();
    this.getPlanoContas();
    this.refs();
  },
  watch: {
    filtro: {
      handler(value) {
        this.$emit('atualizar-filtro', value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    refs() {
      this.$emit('refs', this.$refs);
    },

    //obter dados
    getTipoLancamentoEnum() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-movimento')
        .then(({ data }) => {
          data = data.map((row) => ({
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tiposLancamentoEnum = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getPlanoContas() {
      this.$store.dispatch(START_LOADING);
      PlanoContaService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            text: `Tipo conta: ${row.tipoPlanoConta} | Estrutural: ${row.estrutural} | Natureza: ${row.tipoNatureza} | Descrição: ${row.descricao} `,
            value: row.id,
          }));
          this.opcoes.planosConta = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    gerarRelatorio() {
      this.$emit('gerar-relatorio');
    },

    // FUNÇÕES FILTRO
    filtrar() {
      this.$emit('filtrar');
    },

    limpar() {
      this.filtro = {
        dataInicio: '',
        dataFim: '',
        codigoDocumento: '',
        planoContaId: '',
        objetoReferencia: '',
        tipoMovimento: '',
        valor: '',
      };
      this.$emit('limpar');
    },
  },
};
</script>
