<template>
  <div class="mt-4">
    <b-table
      :fields="fields"
      :items="itens"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      responsive
      show-empty
      striped
    />
  </div>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

export default {
  name: 'TabelaLancamentosPorPeriodo',
  props: {
    itens: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'codigoDocumento',
          label: this.$t('CONTABIL.DOCUMENTO'),
          sortable: true,
        },
        {
          key: 'dataDocumento',
          label: this.$t('CONTABIL.DATA_DOCUMENTO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'objetoReferencia',
          label: this.$t('CONTABIL.OBJETO_REFERENCIA'),
          sortable: true,
        },
        {
          key: 'tipoMovimento',
          label: this.$t('CONTABIL.TIPO_MOVIMENTO'),
          sortable: true,
        },
        {
          key: 'contaContabil',
          label: this.$t('CONTABIL.CONTA_CONTABIL'),
          sortable: true,
        },
        {
          key: 'valor',
          label: this.$t('CONTABIL.VALOR'),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatacaoMoedaBRL(value);
          },
        },
      ],
    };
  },
  methods: {
    remover(item) {
      this.$emit('remover', item);
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  font-size: 12px;
}
</style>
  